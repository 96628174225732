//印尼
export default {
 tip:{
  fromSuccess:'Dikirim dengan sukses'
 },
 login:{
   linkList:[{name:'Star Credits Mall',url:'/'},{name:'Daftar sebagai seorang pedagang',url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'}],
   registered:{
  text:'Tidak punya rekening? Klik Register >>',
  url:'https://tw.fisg.com/register/trader?link_id=ct01egz0&referrer_id=v4w8jplv'
   },
   shouye:{
     url:'/'
   },
forgot : 'Lupakan kata sandi?',
title : 'Login rekening',
email : 'Kotak Surat',
pwd : 'Kata sandi',
denglu : 'GABUNG',
mima : 'Lupakan kata sandi',
Reset : 'Ulangi sandi',
p_email : 'Silakan masukkan email',
p_pwd : 'Silakan masukkan sandi',
p_new_pwd : 'Silakan masukkan sandi anda',
z_email : 'Silakan masukkan alamat email yang benar',
long : '6 hingga 18 karakter dalam panjang',
determine : 'Tentukan',
Valid : 'Aplikasi reset kata sandi telah dikirim. Silakan periksa email Anda dan periksa sambungan reset. Linknya valid dalam 24 jam!',
Help_reset : 'Masukkan alamat email terdaftar Anda dan kami akan membantu Anda reset kata sandi Anda.',

  },
 myhead:{
loginName : 'Login',
Personalcenter : 'Pusat pribadi',
Placeholder : 'Silakan masukkan...',
myhead_a : 'Rumah',
myhead_b : 'Transaksi',
myhead_c : 'Platform',
myhead_bc : 'platform perdagangan',

myhead_d : 'Bantuan dan Sumber daya',
myhead_e : 'Tentang kita',
myhead_f : 'Interstellarfx membantumu',
myhead_g : 'Jadilah pedagang terbaik',
myhead_h : 'Mulai Penjualan',
myhead_i : 'Coba demo gratis',
myhead_j:{
			text:'Aktivitas pemasaran',
			url:'https://www.interstellarfx.net/zh/trade-cashback'
},
myhead_k : 'Kembalian uang pada transaksi',
myhead_l : 'Produk perdagangan',
myhead_m : 'Berkait perdagangan',
myhead_n : 'Sekolah pertukaran asing',
myhead_o:'Titik bonus Festival Musim gugur tengah',
myhead_p:'Pemberitahuan Antarbintang',
myhead_q:'Prakiraan Pasar',

		myhead_r:'Sebagai produk trading finansial yang rumit, contract for difference (CFD) memiliki risiko tinggi kerugian cepat yang timbul dari fitur leverage-nya. Sebagian besar akun investor ritel mencatat kehilangan dana dalam kontrak karena perbedaan. Anda harus mempertimbangkan apakah Anda telah mengembangkan pemahaman penuh tentang aturan operasi kontrak untuk perbedaan dan apakah Anda dapat menanggung risiko kehilangan dana yang tinggi.',
		myhead_s:'Perusahaan',
		myhead_t:'Pasar',
		myhead_u:'Quotasi',
		myhead_v:'Analisis dan Pendidikan',
		myhead_v_a:' Analisis',
		myhead_v_b:' Pendidikan',
		myhead_w:'Pengawasan',
		myhead_x:'Pertanggungan',
		myhead_y:'Kepatuhan Hukum',
		myhead_z:'Berita terakhir',
		myhead_aa:'Panduan Perdagangan',
		myhead_bb:'Daftar Istilah Perdagangan',	
		myhead_cc:'berita waktu nyata',
		myhead_dd:'Analisis Transaksi',
		myhead_ee:'Investasi Forex',
		myhead_ff:'Sponsor & Tanggung jawab sosial',
		myhead_gg:'ISG Webinar',
		myhead_hh:'FISG Analisis',
		myhead_ii:'kursus perdagangan',
		myhead_com:'',

 },
 Home:{
Home_a : 'Star Points Mall',
Home_b : 'Poin perdagangan untuk hadiah!',
Home_c : 'Poin perdagangan untuk hadiah yang baik, fashion digital, peralatan listrik yang indah, mobil mewah atas dan banyak hal baik lain untuk dibawa pulang kapan saja...',
Home_d : 'Tampilkan istilah aktivitas & peraturan berlaku',
Home_e : 'Klik',
Home_f : 'Di sini',
Home_g : 'Hadiah yang bagus, menunggumu',
Home_h : 'Tidak ada hadiah yang kau inginkan?',
Home_i : 'Anda diterima untuk merekomendasikan produk yang Anda inginkan kepada kami kapan saja, kami akan menyediakan produk menurut popularitas hadiah yang direkomendasikan ...',
Home_j : 'Tanda hadiah',
Home_k : 'Deskripsi komoditas',
Home_l : 'Pengiriman',
Home_m : 'Bonus Point Peraturan pertukaran hadiah',
Home_n : 'Bagaimana mendapatkan titik',
Home_o : 'Setiap pelanggan FISG yang bertukar pasangan uang, logam berharga dan produk energi di platform kita akan mendapatkan 1 kredit untuk setiap perdagangan.',
Home_p : 'Bagaimana menukar hadiah',
Home_q : 'Kirim aplikasi pertukaran di pusat mall titik (isi nama Anda, informasi kontak, dan nomor rekening perdagangan dan informasi lainnya), dan latar belakang akan memeriksa titik Anda dan apakah ada transaksi ilegal baru-baru ini, dan mendistribusikan mereka setelah lulus pemeriksaan.',
Home_r : 'Waktu pengiriman hadiah',
Home_s : 'Hadiah akan dikirim dalam 15 hari setelah aplikasi pertukaran diterima, silakan perhatikan bahwa cek, Anda juga dapat menghubungi manajer layanan klien Anda sendiri untuk memeriksa kemajuan pengiriman.',
Home_t : 'Setelah penjualan instruksi',
Home_u : 'Semua barang yang terdaftar di pusat mall integral disediakan oleh penyedia pihak ketiga, dan tidak ada kembalian yang tidak masuk akal didukung. Namun, jika ada hadiah yang salah, atau kerusakan dalam proses pengiriman ekspresi, Anda dapat menghubungi layanan klien eksklusif Anda untuk memecahkan masalah.',
Home_v : 'rekomendasi populer',
Home_w : 'Barang-barang yang paling populer menunggumu di sini',
Home_x : 'Mengubah',
Home_y : 'Titik',
Home_z : 'Pilih tanda',
Home_aa : 'Array produk baru',
Home_bb : 'Isi titik',
Home_cc : 'Pilih tanda',
Home_dd : 'Hanya',
Home_ee : 'Titik yang bisa dikembalikan',
Home_ff : 'Silakan masukkan tanda hadiah',
Home_gg : 'Silakan masukkan deskripsi produk',
Home_hh : 'Terma',
Home_ii : 'Sukses',
Home_zz : 'Pusat Mall Integral',
Home_kk : 'POINT MALL ',
Home_search:'Cari produk'


 },
 detail:{
detail_a : 'Kembali ke halaman rumah pusat mall',
detail_b : 'Model',
detail_c : 'Nomor',
detail_d : 'Instruksi Penukaran Hadiah',
detail_e : 'Hadiah ini akan dikirim dalam 15 hari setelah persetujuan aplikasi pertukaran, silakan perhatikan bahwa cek, Anda juga dapat menghubungi manajer layanan pelanggan dedikasi Anda untuk memeriksa kemajuan pengiriman.',
detail_f : 'Semua barang yang terdaftar di pusat mall Integrpoint disediakan oleh penyedia pihak ketiga, dan kembali tanpa alasan tidak didukung. Namun, jika ada hadiah atau kerusakan yang salah dalam proses pengiriman ekspresi, Anda dapat menghubungi layanan klien eksklusif Anda untuk memecahkan masalah.',
detail_g : 'Perincian produk',
detail_h : 'Rekomendasi popular',
detail_i : 'Silakan log masuk',

 },
 settlement:{
  balance:'Saldo kredit',
  settlement_a : 'Informasi Perintah',
  settlement_b : 'Nama',
  settlement_c : 'Spesifikasi',
  settlement_d : 'Harga unit',
  settlement_e : 'Nomor',
  settlement_f : 'Informasi penerima',
  settlement_g : 'Tambah alamat',
  settlement_h : 'Jumlah penyelesaian',
  settlement_i : 'Titik',
  settlement_z : 'Tepat segera',
  settlement_k : 'Commit berhasil',
  settlement_l:'Silakan konfirmasi perintahmu!',
  settlement_m:'Produk',
  settlement_n:'Informasi produk',
  settlement_o:'Informasi titik!',
  settlement_p:'titik yang diperlukan saat ini tidak cukup',
  settlement_title : 'Untuk sukses',
  settlement_tip : 'Perintah pertukaran Anda akan dikirim dalam 15 hari kerja. Anda juga dapat menghubungi manajer layanan pelanggan dedikasi Anda untuk memeriksa kemajuan pengiriman.',
  settlement_button : 'menentukan',
  settlement_button_err : 'menentukan',

  settlement_tip_error:'Cara mendapatkan integrasi: pertukaran pasangan mata uang, logam mulia, dan produk energi dengan 1 tangan untuk setiap transaksi',
 },
 meber:{
	  meber_a : 'Pusat perintah',
	  meber_b : 'Pusat pribadi',
	  meber_c : 'Perintahku.',
	  meber_d : 'Manajemen alamat',
	  meber_e : 'Daftar keluar',

 }, 
 
 address:{
	address_a : 'Tingkatkan',
	address_b : 'Nama',
	address_c : 'Panggilan',
	address_d : 'Alamat',
	address_e : 'Operasi',
	address_f : 'Menyunting',
	address_g : 'Hapus',
	address_h : 'Silakan pilih',
	address_i : 'Alamat terperinci',
	address_j : 'Simpan',
	address_k : 'Masukkan nama Anda',
	address_l : 'Silakan masukkan nomor telepon Anda',
	address_m : 'Format nomor telepon tidak benar',
	address_n : 'Silakan masukkan nomor telepon Anda',
	address_o : 'Silakan masukkan alamat penuh',
	address_p : 'Berubah dengan sukses',
	address_q : 'Tambah berhasil',
	address_r : 'Hapus berhasil',
	address_s : 'Alamat panen baru',
	address_t : 'Anda tidak menambahkan alamat pengiriman',

 },
 
 order:{
	order_a : 'Perintahku.',
	order_b : 'Tarikh',
	order_c : 'Nomor perintah',
	order_d : 'Penerima',
	order_e : 'Jumlah',
	order_f : 'Perintah rincian',
	order_g : 'Panggilan',
	order_h : 'Alamat',
	order_i : 'Status',
	order_j : 'Mod pengiriman',
	order_k : 'Operasi',
	order_l : 'Tutup',
	order_m : 'Dicipta',
	order_n : 'Dibayar',
	order_o : 'Kembalian dalam proses',
	order_p : 'Diterima',
	order_q : 'Dikirim',
	order_r : 'Selesai',
	order_s : 'Kost integral', 
 },
 
 userinfo:{
	userinfo_a : 'Pusat pribadi',
	userinfo_b : 'waktu kemaskini terakhir',
	userinfo_c : 'Kotak surat pengguna',
	userinfo_d : 'Kredit tersedia',
 },
 
 orderDetail:{
	orderDetail_a : 'Surat kirim',
	orderDetail_b : 'Pembayaran berhasil',
	orderDetail_c : 'Item keluar',
	orderDetail_d : 'Menunggu pengiriman',
	orderDetail_e : 'Selesai',
	orderDetail_f : 'Metode pembayaran',
	orderDetail_g : 'Nama kurir',
	orderDetail_h : 'Nomor pelacak',
 },
 
 footerbox:{ 
	footerbox_b : 'Pasar global hari ini',
	footerbox_c : 'Uji DEMO bebas',
	footerbox_d : 'Asia Pacific Customer Service Singapura Singapura;',
	footerbox_e : 'Email Perusahaan',
	footerbox_f : 'support@interstellarsfx.com',
	footerbox_g : 'MULAI BERDAGANG',
	footerbox_h : 'Forex',
	footerbox_i : 'Logam mulia',
	footerbox_ik : 'Saham',
	footerbox_j : 'Energi',
	footerbox_k : 'Indeks',
	footerbox_a : 'Tentang perdagangan',
	footerbox_l : 'Akun',
	footerbox_m : 'Jenis Akun',
	footerbox_ml : 'Kebijakan Leverage',
	footerbox_n : 'Setoran dan Penarikan',
	footerbox_o : 'Mulai Penjualan',
	footerbox_p : 'Coba demo gratis',
	footerbox_q : 'Peralatan',
	footerbox_r : 'MetaTrader 4',
	footerbox_s : 'MAM',
	footerbox_t : 'MultiTerminal',
	footerbox_u : 'Kalender Ekonomi',
	footerbox_v : 'Kalkulator Forex',
	footerbox_w : 'VPS',
	footerbox_x : 'Central Trading',
	footerbox_y : 'Sekolah Perdagangan',
	footerbox_z : 'Pengumpulan Term',
	footerbox_aa : 'Tentang InterStellar',
	footerbox_ad : 'Perusahaan',
	footerbox_bb : 'Berita',
	footerbox_cc : 'Karir',
	footerbox_dd : 'Hubungi Kami',
	footerbox_ee : 'Kemitraan',
	footerbox_ff : 'FAQ',
	
	footerbox_mm : 'Disclaimer',
	footerbox_nn : `www.interstellarsfx Com beroperasi oleh First Interstellar Global Ltd, perusahaan tanggung jawab terbatas yang didirikan di bawah undang-undang Seychelles, dengan kantor terdaftar di Kamar 9, Bangunan Deenu, Providence, Mahe, Seychelles First Interstellar Global Ltd. First Interstellar Global Ltd diberi otorisasi dan diawasi oleh Penguasa Layanan Finanzial Seychelles di bawah nomor pengawasan SD127. First Interstellar Global Ltd tidak termasuk dalam cadangan regulasi keuangan Eropa. Market for Financial Instruments Directive (MiFID) II dan peraturan layanan keuangan Eropa lainnya tidak berlaku untuk layanan keuangan yang disediakan oleh First Interstellar Global Ltd., dan tidak ada aturan tentang rencana kompensasi investor.`,
	footerbox_gg : 'InterstellarFX tidak menawarkan transaksi CFD kepada penduduk di jurisdiksi tertentu, termasuk Amerika Serikat, Iran, Kanada, Belgia dan Perancis.',
	footerbox_hh : 'Peringatan Risiko:',
	footerbox_ii : 'Penjualan pertukaran asing dan produk keuangan yang berguna adalah resiko tinggi, yang mungkin menyebabkan Anda kehilangan kepala sekolah. Risiko kehilangan yang Anda lakukan tidak harus melebihi toleransi Anda Silakan memastikan bahwa Anda sepenuhnya mengerti risiko yang terlibat. Penjualan produk yang berguna tidak cocok untuk semua investor. Penjualan produk yang tidak berguna (seperti saham) juga melibatkan risiko, karena nilai saham dapat meningkat atau jatuh, yang berarti dana yang Anda terima mungkin lebih rendah dari investasi awal Anda. Pertunjukan masa lalu tidak menjamin hasil masa depan. Sebelum perdagangan, silakan pertimbangkan tingkat pengalaman dan tujuan investasi Anda, dan mencari nasihat keuangan independen jika perlu. Ini adalah tanggung jawab pelanggan untuk memastikan bahwa undang-undang negara tinggal nya memungkinkan dia untuk menggunakan layanan yang diberikan oleh StarCraft.',
	footerbox_zz : 'Penjelasan Risiko',
	footerbox_kk : 'Polisi Privasi',
  footerbox_ll:'Copyright © 2021 FISG. All rights reserved.',
 }
 
}