<template>
  <div class="head" :class="{'lang-th':lang=='th' ,'lang-vi':lang=='vi'}">
    <div class="head_fix" id="head_fix_box">
      <div class="header container flex a-center jlr j-center">

          <div class="phoneMenu phoneMenu_2" @click="toogNav()">
          <!-- <i :class="phoneIcon"></i> -->
          <!-- <svg id="svg_1"
            t="1690266589372"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="6712"
            width="32"
            height="30"
          >
            <path
              d="M136.53504 260.48h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.02496s-25.97888-58.02496-58.01984-58.02496H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.02496s25.97888 58.02496 58.02496 58.02496zM887.47008 434.56H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.02496s25.97888 58.02496 58.02496 58.02496h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.02496s-25.97888-58.02496-58.01984-58.02496zM887.47008 714.44992H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.03008s25.97888 58.03008 58.02496 58.03008h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.03008s-25.97888-58.03008-58.01984-58.03008z"
              p-id="6713"
              fill="#000000"
            ></path>
          </svg> -->

          
          <svg  version="1.1" id="svg_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32" height="30" viewBox="0 0 55 42" enable-background="new 0 0 55 42" xml:space="preserve">  <image id="image0" width="55" height="42" x="0" y="0"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAAqCAYAAAAXk8MDAAAACXBIWXMAABcRAAAXEQHKJvM/AAABSklEQVRoge3ZsU3DQBTG8b9vAo/gbBA6dxDJPUTyAGQDvEE2CJkAD3ASHuAkUl7pDbgN8AZQPFwEiQqfHj7dr3T1PT37Tp9cANRNewe8ABXrFoDOOzsAFN+DvWkmiuDgne2LumnfWf/GfpqAjSG9wQBKYGu0U8RkkBWmKBjgrJ0igt47G4x39gg8a6dZ0AB0AMX8pG7aCnhAPsa1Gryzo3aILMuy7N9I+p4rAOqmPQFPapGWNSB9bjJ10x5JZzCQt+8E0sQ/WPer+JuNIc3BAKrky2rQDhHB5J29GOCgnSSCDsB4Zy/AjjQ2GIC9d7ZXzpFlWZZl0c19rkRqz61unD8bgbN3NoBUnhL5+bjVTLWgCdh5Z0eDFLtUBgOpcK8gm/tUDhPLTcp9rkx5uMkAvXaKCMJ8oHTIEZqKCdjD9T33CNzrZVrE1T33Bc9aWMHmrGUrAAAAAElFTkSuQmCC" ></image>
          </svg>

          <svg  version="1.1" id="svg_2"  style="display: none;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32" height="32" viewBox="0 0 55 42" enable-background="new 0 0 55 42" xml:space="preserve">  <image id="image0" width="55" height="42" x="0" y="0"
              href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADcAAAAqCAYAAAAXk8MDAAAACXBIWXMAABcRAAAXEQHKJvM/AAACZ0lEQVRogb3aMW7bMBSA4T/tBTx28GB4Cjx51BYH0N4MOoAydswReoL2BvEBBDQHEJBk06jJo5EAHjL6CB1MoYYb8omPj3pTAIkiP1MS9R5zhRBFWc2AGrgBjsBr1zZbqZ1lFGV1B3wHFsArsO3a5k1qdyVcdA08A7OLQz1w27XNUTPYmCjK6pHTj3seR+BB+pG/CNf+w/8wgDXw7GY1W3hguDH9KspqEWrvxRVlteF0G/giKzAAG2IG3IWuEZq5zYgxZAGOgA0R7DeE60eOxRQYARMjhHvh9OCOCROgAvYUOujFuTfhQ0RHSUAF7HfXNsG7K7gUuE5r4DGi0+hlQgHbdm1zL50k4lznNZmAuWAwEucGUWMMzAmDCJwbTI0RMDcMInFgA5wCBgocpAGngoESB2pgz0QwSMCBChgTSTCArymND/tdP1+u3hE+YBWRDINEHGQBmsDAAAemQDMYGOHABGgKA0McJAHNYSCXGTRxo2izzpHRJy0Fl5GYaJoXncxwRhm0KdAEZ1kawBCYjDOGDWECTP38ygEbIhmofltqvu6BmNd9ctFJm/Ko05YpajJDaJLV5HxsKmBsmcEs0ZwCGFMgMs+gcwPHlvaylQZyAscUZbPXPHIBg0vBVMUct4kYvUxIJ3lTHrext43oMCltUaRL3+bL1fthv/PuF4RmLiYnM8nHFDO4CB20yOdME00F0BvS/pwUWTLoCOBb6KD3mTvsdx/z5WoNXHtOyQI76196BvuubX6EriHdlvd8vnv5MydsiLMZvHzlvwC3Uvuxi/iCfw9vP8X/n3wyho378yjtqA7xF9p2aXEyxL4oAAAAAElFTkSuQmCC" ></image>
          </svg>

          <!-- <svg id="svg_2" style="display: none;" t="1736990226227" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1580" width="32" height="32">
            
            <path d="M720.298667 768c-12.714667 0-23.850667-4.778667-33.408-14.293333L270.293333 337.066667c-19.072-19.114667-19.072-49.322667 0-66.816 19.114667-19.072 49.322667-19.072 66.816 0l416.597334 415.018666c19.072 19.072 19.072 49.28 0 66.773334-9.557333 11.136-22.272 15.914667-33.408 15.914666z" fill="#172B4D" p-id="1581"></path><path d="M303.701333 768c-12.714667 0-23.850667-4.778667-33.408-14.293333-19.072-19.114667-19.072-49.322667 0-66.816l415.018667-416.597334c19.072-19.072 49.28-19.072 66.773333 0 19.114667 19.114667 19.114667 49.322667 0 66.816l-414.976 416.597334a45.781333 45.781333 0 0 1-33.408 14.293333z" fill="#172B4D" p-id="1582">

            </path>
          </svg> -->
        </div>

        <div class="logo logo_pc">
          <a :href="host + lang + '/home'"> <img src="https://www.fisg.com/wp-content/uploads/2024/09/logo-1.svg" /></a>
          <!-- <a :href="host + lang + '/home'"> <img src="../assets/logo.png" /></a> -->
           
        </div>
        <!-- <div class="logo"><img src="../assets/logo-square.svg"></div> -->
        <div class="nav_box">
          <ul class="nav flex" id="nav">
            <li class="submenu_box">
              <h4 @click="pageTo(5)">{{ $t("myhead.myhead_s") }}</h4>
              <div class="submenu">
                <div class="anniu_guanbi">
                  <i class="el-icon-close"></i>
                </div>
                <div class="container submenu_box_ner flex">
                  <div class="b_left">
                    <b>{{ $t("myhead.myhead_f") }}</b>
                    <b>{{ $t("myhead.myhead_g") }}</b>
                    <div class="b_left_anniu">
                      <h3 class="b_left_anniu_a">
                        <a :href="$t('login.registered.url')"
                          ><span>{{ $t("footerbox.footerbox_o") }}</span></a
                        >
                      </h3>
                      <h3 class="b_left_anniu_b">
                        <a :href="$t('login.registered.url')"
                          ><span>{{ $t("footerbox.footerbox_p") }}</span></a
                        >
                      </h3>
                    </div>
                  </div>
                  <div class="b_right_box">
                    <div class="b_right flex" style="">
                      <dl>
                        <dt>{{ $t("footerbox.footerbox_ad") }}</dt>

                        <dd>
                          <a :href="host + lang + '/about_us'">
                            {{ $t("footerbox.footerbox_aa") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/insurance'">
                            {{ $t("myhead.myhead_x") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/legal-compliance'">
                            {{ $t("myhead.myhead_y") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/interstellar-notice'">
                            {{ $t("myhead.myhead_p") }}</a
                          >
                        </dd>

                        <dd>
                          <a
                            :href="
                              host + lang + '/sponsorship-social-responsibility'
                            "
                          >
                            {{ $t("myhead.myhead_ff") }}</a
                          >
                        </dd>

                      </dl>

                    </div>
                    <div class="b_right_icon flex j-end">
                      <div class="b_right_icon_left flex a-center">
                        <div class="b_right_icon_left_tb"></div>
                        <div>
                          <a :href="host + lang + '/faq'">
                            {{ $t("footerbox.footerbox_ff") }}</a
                          >
                        </div>
                      </div>

                      <div class="b_right_icon_right flex a-center j-center">
                        <div class="b_right_icon_right_tb"></div>
                        <div>
                          <a :href="host + lang + '/contact-us'">
                            {{ $t("footerbox.footerbox_dd") }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- 关于我们end -->

            <li class="submenu_box">
              <h4 @click="pageTo(3)">{{ $t("myhead.myhead_j.text") }}</h4>
              <div class="submenu">
                <div class="anniu_guanbi">
                  <i class="el-icon-close"></i>
                </div>
                <div class="container submenu_box_ner flex">
                  <div class="b_right_box">
                    <div class="b_right flex" style="">
                      <dl >
                        <dt>{{ $t("myhead.myhead_j.text") }}</dt>
                        
                        <dd v-if="
                            lang == 'th' 
                          ">
                          <a
                            :href="
                              host + + lang + '/seminar'
                            "
                          >
                          สัมมนา</a
                          >
                        </dd>

                        <dd v-if="
                            lang == 'th' 
                          ">
                          <a
                            :href="
                              host + + lang + '/rade-for-prizes-2025'
                            "
                          >
                          Trade For Prizes 2025</a
                          >
                        </dd>

                        <dd v-if="
                            lang == 'th' 
                          ">
                          <a
                            :href="
                              host + + lang + '/trade-fin-win-paris'
                            "
                          >
                          เทรดฟิน บินปารีส</a
                          >
                        </dd>


                        <dd v-if="
                            lang == 'vi' 
                          ">
                          <a
                            :href="
                              host + + lang + '/deposit-bonus'
                            "
                          >
                          BONUS 10% TÀI KHOẢN</a
                          >
                        </dd>
                        <dd v-if="
                            lang == 'vi' 
                          ">
                          <a
                            :href="
                              host + + lang + '/sweepstakes'
                            "
                          >
                          CHƯƠNG TRÌNH KHÁCH HÀNG THÂN THIẾT</a
                          >
                        </dd>
                     
                        <dd v-if="
                            lang == 'en' ||
                            lang == 'vi' ||
                            lang == 'th' ||
                            lang == 'ru' ||
                            lang == 'zh'
                          ">
                          <a
                            :href="
                              host +  '/landing/competition_'+ lang + '.html'
                            "
                          >
                            {{ $t("myhead.myhead_com") }}</a
                          >
                        </dd>
                        <dd v-if="
                            lang == 'zh-tw' 
                          ">
                          <a
                            :href="
                              host +  '/landing/competition_'+ 'tw' + '.html'
                            "
                          >
                            {{ $t("myhead.myhead_com") }}</a
                          >
                        </dd>

                      </dl>

                    </div>
                    <div class="b_right_icon flex j-end">
                      <div class="b_right_icon_left flex a-center">
                        <div class="b_right_icon_left_tb"></div>
                        <div>
                          <a :href="host + lang + '/faq'">
                            {{ $t("footerbox.footerbox_ff") }}</a
                          >
                        </div>
                      </div>

                      <div class="b_right_icon_right flex a-center j-center">
                        <div class="b_right_icon_right_tb"></div>
                        <div>
                          <a :href="host + lang + '/contact-us'">
                            {{ $t("footerbox.footerbox_dd") }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- 市场活动end -->

            <li class="submenu_box" style="position: static;">
              <h4 @click="pageTo(3)">{{ $t("myhead.myhead_b") }}</h4>
              <div class="submenu b_right_box_2">
                <div class="anniu_guanbi">
                  <i class="el-icon-close"></i>
                </div>
                <div class="container submenu_box_ner flex">
                  <div class="b_left">
                    <b>{{ $t("myhead.myhead_f") }}</b>
                    <b>{{ $t("myhead.myhead_g") }}</b>
                    <div class="b_left_anniu">
                      <h3 class="b_left_anniu_a">
                        <a :href="$t('login.registered.url')"
                          ><span>{{ $t("footerbox.footerbox_o") }}</span></a
                        >
                      </h3>
                      <h3 class="b_left_anniu_b">
                        <a :href="$t('login.registered.url')"
                          ><span>{{ $t("footerbox.footerbox_p") }}</span></a
                        >
                      </h3>
                    </div>
                  </div>
                  <div class="b_right_box ">
                    <div class="b_right flex">
					  
                      <dl>
                        <dt>
                          <!-- {{ $t("myhead.myhead_b") }}{{ $t("myhead.myhead_c") }} -->
                          {{ $t("myhead.myhead_bc") }}
                        </dt>
                      
                        <dd>
                          <a :href="host + lang + '/metatrader-4'">
                            MT4</a
                          >
                        </dd>
                      
                        <dd>
                          <a :href="host + lang + '/mt5'">
                            MT5</a
                          >
                        </dd>
                      
                        <dd>
                          <a :href="host + lang + '/metatrader-webterminal'"
                          >
                            MetaTrader WebTerminal</a
                          >
                        </dd>
                      
                        <dd
                          v-if="
                            lang == 'en' ||
                            lang == 'vi' ||
                            lang == 'th' ||
                            lang == 'id' ||
                            lang == 'ko' ||
                            lang == 'ja' ||
                            lang == 'zh-tw' ||
                            lang == 'ru' ||
                            lang == 'zh' ||
                            lang == 'ms' 
                          "
                        >
                          <a :href="host + lang + '/copy-trade'">Copy Trade</a>
                        </dd>
                      </dl>
                      <dl>
                        <dt>{{ $t("myhead.myhead_l") }}</dt>
                            
                        <dd>
                          <a :href="host + lang + '/forex-trade'">
                            {{ $t("footerbox.footerbox_h") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/metals-trade'">
                            {{ $t("footerbox.footerbox_i") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/energy-trade'">
                            {{ $t("footerbox.footerbox_j") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/indices-trade'">
                            {{ $t("footerbox.footerbox_k") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/stocks'">
                            {{ $t("footerbox.footerbox_ik") }}</a
                          >
                        </dd>
                      </dl>
                      <dl>
                        <dt>{{ $t("footerbox.footerbox_l") }}</dt>
                        <dd>
                          <a :href="host + lang + '/account-type'">
                            {{ $t("footerbox.footerbox_m") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/leverage-policy'">
                            {{ $t("footerbox.footerbox_ml") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/deposit-withdrawal'">
                            {{ $t("footerbox.footerbox_n") }}</a
                          >
                        </dd>
                        <dd v-if="lang == 'th'">
                          <a :href="host + lang + '/นโยบายเลเวอเรจ'">
                            นโยบายเลเวอเรจ</a
                          >
                        </dd>
                      </dl>
					  
					  

                      <dl>
                        <dt>{{ $t("footerbox.footerbox_q") }}</dt>
                        <dd>
                          <a :href="host + lang + '/mam'">
                            {{ $t("footerbox.footerbox_s") }}</a
                          >
                        </dd>
                        <dd><a :href="host + lang + '/PAMM'"> PAMM</a></dd>
                        <dd>
                          <a :href="host + lang + '/multi-terminal-title'">
                            {{ $t("footerbox.footerbox_t") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/calculator'">
                            {{ $t("footerbox.footerbox_v") }}</a
                          >
                        </dd>
                        
                      </dl>

                    </div>
                    <div class="b_right_icon flex j-end">
                      <div class="b_right_icon_left flex a-center">
                        <div class="b_right_icon_left_tb"></div>
                        <div>
                          <a :href="host + lang + '/faq'">
                            {{ $t("footerbox.footerbox_ff") }}</a
                          >
                        </div>
                      </div>

                      <div class="b_right_icon_right flex a-center j-center">
                        <div class="b_right_icon_right_tb"></div>
                        <div>
                          <a :href="host + lang + '/contact-us'">
                            {{ $t("footerbox.footerbox_dd") }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- 交易工具end -->

 

            <li class="submenu_box">
              <h4 @click="pageTo(4)">{{ $t("myhead.myhead_v_b") }}</h4>
              <div class="submenu">
                <div class="anniu_guanbi">
                  <i class="el-icon-close"></i>
                </div>
                <div class="container submenu_box_ner flex">
                  <div class="b_left">
                    <b>{{ $t("myhead.myhead_f") }}</b>
                    <b>{{ $t("myhead.myhead_g") }}</b>
                    <div class="b_left_anniu">
                      <h3 class="b_left_anniu_a">
                        <a :href="$t('login.registered.url')"
                          ><span>{{ $t("footerbox.footerbox_o") }}</span></a
                        >
                      </h3>
                      <h3 class="b_left_anniu_b">
                        <a :href="$t('login.registered.url')"
                          ><span>{{ $t("footerbox.footerbox_p") }}</span></a
                        >
                      </h3>
                    </div>
                  </div>
                  <div class="b_right_box">
                    <div class="anniu_guanbi">
                      <i class="el-icon-close"></i>
                    </div>
                    <div class="b_right flex" style="">

                      <dl>
                        <dt>{{ $t("myhead.myhead_v_b") }}</dt>
                        <!-- <dd>
                          <a :href="host + lang + '/isg-webinar'">
                            {{ $t("myhead.myhead_gg") }}</a
                          >
                        </dd> -->
						
                        <dd>
                          <a :href="host + lang + '/trading-course'">
                            {{ $t("myhead.myhead_ii") }}</a
                          >
                        </dd>
						
                        <dd>
                          <a
                            :href="host + lang + '/educational-articles-one-1'"
                          >
                            {{ $t("myhead.myhead_ee") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/assembly'">
                            {{ $t("myhead.myhead_bb") }}</a
                          >
                        </dd>
                        <dd>
                          <a :href="host + lang + '/faq'">
                            {{ $t("footerbox.footerbox_ff") }}</a
                          >
                        </dd>
                        

                      </dl>
                    </div>
                    <div class="b_right_icon flex j-end">
                      <div class="b_right_icon_left flex a-center">
                        <div class="b_right_icon_left_tb"></div>
                        <div>
                          <a :href="host + lang + '/faq'">
                            {{ $t("footerbox.footerbox_ff") }}</a
                          >
                        </div>
                      </div>

                      <div class="b_right_icon_right flex a-center j-center">
                        <div class="b_right_icon_right_tb"></div>
                        <div>
                          <a :href="host + lang + '/contact-us'">
                            {{ $t("footerbox.footerbox_dd") }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- 教育end -->
			
			
			
			<li>
			  <h4 @click="pageTo(2)">{{ $t("myhead.myhead_t") }}</h4>
			  <div class="submenu">
			    <div class="anniu_guanbi">
			      <i class="el-icon-close"></i>
			    </div>
			    <div class="container submenu_box_ner flex">
			      <div class="b_left">
			        <b>{{ $t("myhead.myhead_f") }}</b>
			        <b>{{ $t("myhead.myhead_g") }}</b>
			        <div class="b_left_anniu">
			          <h3 class="b_left_anniu_a">
			            <a :href="$t('login.registered.url')"
			              ><span>{{ $t("footerbox.footerbox_o") }}</span></a
			            >
			          </h3>
			          <h3 class="b_left_anniu_b">
			            <a :href="$t('login.registered.url')"
			              ><span>{{ $t("footerbox.footerbox_p") }}</span></a
			            >
			          </h3>
			        </div>
			      </div>
			
			      <div class="b_right_box">
			        <div class="b_right flex" style="">
			          <dl>
			            <dt>{{ $t("myhead.myhead_t") }}</dt>
						
						<dd>
						  <a :href="host + lang + '/fisg-analysis'">
						   {{ $t("myhead.myhead_hh") }}</a
						  >
						</dd>
			
                        <dd>
                          <a :href="host + lang + '/economic-calendar'">
                            {{ $t("footerbox.footerbox_u") }}</a
                          >
                        </dd>
						
						<dd>
						  <a :href="host + lang + '/transaction'">
						    {{ $t("myhead.myhead_dd") }}</a
						  >
						</dd>
						
						<dd>
						  <a :href="host + lang + '/real-time-news'">
						    {{ $t("myhead.myhead_cc") }}</a
						  >
						</dd>
			          </dl>
			        </div>
			        <div class="b_right_icon flex j-end">
			          <div class="b_right_icon_left flex a-center">
			            <div class="b_right_icon_left_tb"></div>
			            <div>
			              <a :href="host + lang + '/faq'">
			                {{ $t("footerbox.footerbox_ff") }}</a
			              >
			            </div>
			          </div>
			
			          <div class="b_right_icon_right flex a-center j-center">
			            <div class="b_right_icon_right_tb"></div>
			            <div>
			              <a :href="host + lang + '/contact-us'">
			                {{ $t("footerbox.footerbox_dd") }}</a
			              >
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</li>
			<!-- 市场end -->


            <!-- <li> -->
              <!-- <h4><a class="shouye_huan" href="https://mall.islfx.com/">{{$t('Home.Home_kk')}}</a></h4> -->
              <!-- <h4><a class="shouye_huan" href="https://mall.islfx.com/">{{$t('Home.Home_kk')}}</a></h4> -->
              <!-- <h4 class="integral_sj" @click="pageTo(1)" style="display: none">
                {{ $t("Home.Home_kk") }}
              </h4> -->
            <!-- </li> -->
            <!-- 积分商城end -->

            <li>
              <h4 @click="pageTo(4)">{{ $t("footerbox.footerbox_ee") }}</h4>
              <div class="submenu">
                <div class="anniu_guanbi">
                  <i class="el-icon-close"></i>
                </div>
                <div class="container submenu_box_ner flex">
                  <div class="b_left">
                    <b>{{ $t("myhead.myhead_f") }}</b>
                    <b>{{ $t("myhead.myhead_g") }}</b>
                    <div class="b_left_anniu">
                      <h3 class="b_left_anniu_a">
                        <a :href="$t('login.registered.url')"
                          ><span>{{ $t("footerbox.footerbox_o") }}</span></a
                        >
                      </h3>
                      <h3 class="b_left_anniu_b">
                        <a :href="$t('login.registered.url')"
                          ><span>{{ $t("footerbox.footerbox_p") }}</span></a
                        >
                      </h3>
                    </div>
                  </div>
                  <div class="b_right_box">
                    <div class="anniu_guanbi">
                      <i class="el-icon-close"></i>
                    </div>
                    <div class="b_right flex" style="">
                      <dl>
                        <dt>{{ $t("footerbox.footerbox_ee") }}</dt>
                        <dd>
                          <a :href="host + lang + '/partnership'">
                            {{ $t("footerbox.footerbox_ee") }}</a
                          >
                        </dd>
                      </dl>
                    </div>
                    <div class="b_right_icon flex j-end">
                      <div class="b_right_icon_left flex a-center">
                        <div class="b_right_icon_left_tb"></div>
                        <div>
                          <a :href="host + lang + '/faq'">
                            {{ $t("footerbox.footerbox_ff") }}</a
                          >
                        </div>
                      </div>

                      <div class="b_right_icon_right flex a-center j-center">
                        <div class="b_right_icon_right_tb"></div>
                        <div>
                          <a :href="host + lang + '/contact-us'">
                            {{ $t("footerbox.footerbox_dd") }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- 合作伙伴end -->

            <div
              id="langGrou_sj3"
              class="langGroup"
              style="position: relative; display: none"
            >
              <h4 class="flex a-center" @click.stop>
                <div class="nationalicon" :class="[lang]"></div>
                {{ lang | langFilter }}
                <i class="el-icon-caret-bottom"></i>
              </h4>
              <div class="national">
                <div class="item flex a-center" @click="langFn('en')">
                  <div class="nationalicon en"></div>
                  ENGLISH
                </div>

                <div class="item flex a-center" @click="langFn('id')">
                  <div class="nationalicon id"></div>
                  INDONESIA
                </div>

                <div class="item flex a-center" @click="langFn('ja')">
                  <div class="nationalicon ja"></div>
                  日本語
                </div>

                <div class="item flex a-center" @click="langFn('ko')">
                  <div class="nationalicon ko"></div>
                  한국어
                </div>

                <div class="item flex a-center" @click="langFn('ms')">
                  <div class="nationalicon ms"></div>
                  MELAYU
                </div>

                <div class="item flex a-center" @click="langFn('ru')">
                  <div class="nationalicon ru"></div>
                  РУССКИЙ
                </div>

                <div class="item flex a-center" @click="langFn('th')">
                  <div class="nationalicon th"></div>
                  ไทย
                </div>

                <div class="item flex a-center" @click="langFn('vi')">
                  <div class="nationalicon vi"></div>
                  TIẾNG VIỆT
                </div>

                <div class="item flex a-center" @click="langFn('zh')">
                  <div class="nationalicon zh"></div>
                  简体中文
                </div>

                <div class="item flex a-center" @click="langFn('zh-tw')">
                  <div class="nationalicon zh-tw"></div>
                  繁体中文
                </div>
              </div>
            </div>

            <!-- <div class="login_button flex a-center j-center"  @click="pageTo(6)">登录</div> -->
            <!-- <div
              class="login_button flex a-center j-center"
              style="margin-left: 10px"
            >
              <button
                v-if="!anniu_login"
                @click="pageTo(6)"
                class="login_button flex a-center j-center"
              >
                <svg
                  t="1690278123418"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="9664"
                  width="16"
                  height="16"
                >
                  <path
                    d="M535.6 511.8c95.1-10.5 172.1-87.5 182.6-182.6C732 204 634.4 98.2 512 98.2c-114.6 0-207.5 92.8-207.5 207.4v0.1c0 122.4 105.9 219.9 231.1 206.1zM512 572.4c-138.5 0-415 79.4-415 237.1v88.9c0 16.4 13.3 29.6 29.6 29.6h770.7c16.4 0 29.6-13.3 29.6-29.6v-88.9C927 651.9 650.5 572.4 512 572.4z"
                    p-id="9665"
                    fill="#ffffff"
                  ></path>
                </svg>
                {{ $t("myhead.loginName") }}
              </button>
              <button
                v-else
                @click="enter('/meber')"
                class="login_button flex a-center j-center"
              >
                <svg
                  t="1690278123418"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="9664"
                  width="16"
                  height="16"
                >
                  <path
                    d="M535.6 511.8c95.1-10.5 172.1-87.5 182.6-182.6C732 204 634.4 98.2 512 98.2c-114.6 0-207.5 92.8-207.5 207.4v0.1c0 122.4 105.9 219.9 231.1 206.1zM512 572.4c-138.5 0-415 79.4-415 237.1v88.9c0 16.4 13.3 29.6 29.6 29.6h770.7c16.4 0 29.6-13.3 29.6-29.6v-88.9C927 651.9 650.5 572.4 512 572.4z"
                    p-id="9665"
                    fill="#ffffff"
                  ></path>
                </svg>
                {{ $t("myhead.Personalcenter") }}
              </button>
            </div> -->
          </ul>
        </div>



        <div class="right-nav right-nav-p">
          <div class="transaction_box">
            <a :href="$t('login.registered.url')">{{$t("footerbox.footerbox_g")}}</a>
          </div>

          <div v-if="!anniu_login" class="login_button flex a-center j-center"  @click="pageTo(6)">
            <svg  xmlns="http://www.w3.org/2000/svg" width="38" height="39" viewBox="0 0 38 39" fill="none"><path d="M19.0799 38.0995C15.4052 38.0995 11.8129 37.0098 8.75742 34.9682C5.70196 32.9266 3.32056 30.0248 1.91428 26.6297C0.508008 23.2347 0.140119 19.4988 0.857033 15.8947C1.57395 12.2905 3.34353 8.97987 5.94199 6.38141C8.54045 3.78296 11.851 2.01338 15.4552 1.29647C19.0593 0.579558 22.7952 0.947503 26.1903 2.35378C29.5853 3.76006 32.4871 6.1415 34.5287 9.19697C36.5703 12.2524 37.66 15.8447 37.66 19.5195C37.6494 24.4439 35.6885 29.1637 32.2064 32.6458C28.7243 36.128 24.0044 38.0889 19.0799 38.0995ZM28.28 23.6095C27.7591 23.2819 27.183 23.0515 26.5799 22.9295C25.0499 22.5895 23.17 22.4195 21.98 20.9295C21.98 20.9295 21.2999 20.2495 22.8299 18.5495C24.3599 16.8495 24.36 14.1195 24.02 12.0695C23.7189 11.0842 23.1095 10.2217 22.2813 9.60882C21.4532 8.99597 20.4502 8.6652 19.42 8.6652C18.3898 8.6652 17.3868 8.99597 16.5587 9.60882C15.7306 10.2217 15.121 11.0842 14.8199 12.0695C14.4799 14.0695 14.65 16.8395 16.01 18.5495C17.55 20.2495 16.87 20.9295 16.87 20.9295C15.67 22.4695 13.8 22.6395 12.26 22.9295C11.6569 23.0515 11.0809 23.2819 10.56 23.6095C9.56004 24.1195 10.05 28.7195 10.05 28.7195H28.62C28.28 28.7195 28.62 24.2895 28.28 23.6095Z" fill="#384454"></path></svg>
            {{$t("login.denglu")}}</div> 
            
            <div  v-else  class="login_button flex a-center j-center"@click="enter('/meber')">
              
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#384454" class="bi bi-person-circle" viewBox="0 0 16 16">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
              <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>
                {{ $t("myhead.Personalcenter") }}
              </div>

            <div class="login_button login_button_3 flex a-center j-center"  @click="pageTo(7)">
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37" viewBox="0 0 34 37" fill="none"><path d="M32.2899 5.63926C32.2938 4.96689 32.1652 4.30032 31.9116 3.67762C31.6579 3.05492 31.2841 2.48828 30.8115 2.01006C30.3388 1.53184 29.7766 1.15139 29.1569 0.890451C28.5373 0.629512 27.8722 0.493186 27.1998 0.489258H6.83981C6.16744 0.493186 5.50246 0.629512 4.88278 0.890451C4.26309 1.15139 3.70089 1.53184 3.22824 2.01006C2.75559 2.48828 2.38168 3.05492 2.12802 3.67762C1.87436 4.30032 1.74589 4.96689 1.74984 5.63926L0.0498931 31.3993C0.0459419 32.0716 0.174407 32.7382 0.428067 33.3609C0.681727 33.9836 1.05551 34.5502 1.52816 35.0285C2.00082 35.5067 2.56314 35.8871 3.18283 36.1481C3.80251 36.409 4.46749 36.5453 5.13986 36.5493H28.8999C29.5731 36.5466 30.2391 36.4113 30.86 36.1509C31.4808 35.8906 32.0442 35.5103 32.5179 35.032C32.9916 34.5536 33.3664 33.9865 33.6207 33.3632C33.875 32.7398 34.0038 32.0725 33.9998 31.3993L32.2899 5.63926ZM26.6198 7.47926C26.6269 9.99459 25.6383 12.4105 23.8698 14.1993C23.0026 15.0797 21.969 15.7789 20.8291 16.2561C19.6891 16.7334 18.4656 16.9792 17.2298 16.9792C15.994 16.9792 14.7705 16.7334 13.6306 16.2561C12.4906 15.7789 11.457 15.0797 10.5898 14.1993C8.8241 12.4095 7.83897 9.99338 7.84982 7.47926H10.1499C10.1499 9.35699 10.8957 11.1578 12.2235 12.4856C13.5512 13.8133 15.3521 14.5593 17.2298 14.5593C19.1076 14.5593 20.9084 13.8133 22.2362 12.4856C23.5639 11.1578 24.3099 9.35699 24.3099 7.47926H26.6198Z" fill="#384454"></path></svg>
            {{  $t("Home.Home_kk") }}</div> 
          
            <div class="login_button login_button_2 flex a-center j-center">
              <div
              id="langGrou_sj2"
              class="langGroup"
              style="position: relative; display: block"
            >
              <h4 class="flex a-center" @click.stop>
                <!-- <div class="nationalicon" :class="[lang]"></div> -->
                <!-- {{ lang | langFilter }} -->
                <div class="trans_box"></div>
                <!-- <i class="el-icon-caret-bottom"></i> -->
              </h4>
              <div class="national">
                <div class="item flex a-center" @click="langFn('en')">
                  <div class="nationalicon en"></div>
                  ENGLISH
                </div>

                <div class="item flex a-center" @click="langFn('id')">
                  <div class="nationalicon id"></div>
                  INDONESIA
                </div>

                <div class="item flex a-center" @click="langFn('ja')">
                  <div class="nationalicon ja"></div>
                  日本語
                </div>

                <div class="item flex a-center" @click="langFn('ko')">
                  <div class="nationalicon ko"></div>
                  한국어
                </div>

                <div class="item flex a-center" @click="langFn('ms')">
                  <div class="nationalicon ms"></div>
                  MELAYU
                </div>

                <div class="item flex a-center" @click="langFn('ru')">
                  <div class="nationalicon ru"></div>
                  РУССКИЙ
                </div>

                <div class="item flex a-center" @click="langFn('th')">
                  <div class="nationalicon th"></div>
                  ไทย
                </div>

                <div class="item flex a-center" @click="langFn('vi')">
                  <div class="nationalicon vi"></div>
                  TIẾNG VIỆT
                </div>

                <div class="item flex a-center" @click="langFn('zh')">
                  <div class="nationalicon zh"></div>
                  简体中文
                </div>

                <div class="item flex a-center" @click="langFn('zh-tw')">
                  <div class="nationalicon zh-tw"></div>
                  繁体中文
                </div>
              </div>
            </div>
            </div> 
        </div>

        <div class="right-nav right-nav-m">
          <div class="transaction_box">
            <a :href="$t('login.registered.url')">{{$t("footerbox.footerbox_g")}}</a>
          </div>

          <div v-if="!anniu_login" class="login_button flex a-center j-center"  @click="pageTo(6)">
            <svg color  xmlns="http://www.w3.org/2000/svg" width="38" height="39" viewBox="0 0 38 39" fill="none"><path d="M19.0799 38.0995C15.4052 38.0995 11.8129 37.0098 8.75742 34.9682C5.70196 32.9266 3.32056 30.0248 1.91428 26.6297C0.508008 23.2347 0.140119 19.4988 0.857033 15.8947C1.57395 12.2905 3.34353 8.97987 5.94199 6.38141C8.54045 3.78296 11.851 2.01338 15.4552 1.29647C19.0593 0.579558 22.7952 0.947503 26.1903 2.35378C29.5853 3.76006 32.4871 6.1415 34.5287 9.19697C36.5703 12.2524 37.66 15.8447 37.66 19.5195C37.6494 24.4439 35.6885 29.1637 32.2064 32.6458C28.7243 36.128 24.0044 38.0889 19.0799 38.0995ZM28.28 23.6095C27.7591 23.2819 27.183 23.0515 26.5799 22.9295C25.0499 22.5895 23.17 22.4195 21.98 20.9295C21.98 20.9295 21.2999 20.2495 22.8299 18.5495C24.3599 16.8495 24.36 14.1195 24.02 12.0695C23.7189 11.0842 23.1095 10.2217 22.2813 9.60882C21.4532 8.99597 20.4502 8.6652 19.42 8.6652C18.3898 8.6652 17.3868 8.99597 16.5587 9.60882C15.7306 10.2217 15.121 11.0842 14.8199 12.0695C14.4799 14.0695 14.65 16.8395 16.01 18.5495C17.55 20.2495 16.87 20.9295 16.87 20.9295C15.67 22.4695 13.8 22.6395 12.26 22.9295C11.6569 23.0515 11.0809 23.2819 10.56 23.6095C9.56004 24.1195 10.05 28.7195 10.05 28.7195H28.62C28.28 28.7195 28.62 24.2895 28.28 23.6095Z" fill="#384454"></path></svg>
             </div>
            <div
                v-else
                @click="enter('/meber')"
                class="login_button flex a-center j-center"
              >
              
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#384454" class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
              </svg>
                <!-- {{ $t("myhead.Personalcenter") }} -->
         
            </div> 
            <div class="login_button login_button_3 flex a-center j-center"  @click="pageTo(7)">
              <svg xmlns="http://www.w3.org/2000/svg" width="34" height="37" viewBox="0 0 34 37" fill="none"><path d="M32.2899 5.63926C32.2938 4.96689 32.1652 4.30032 31.9116 3.67762C31.6579 3.05492 31.2841 2.48828 30.8115 2.01006C30.3388 1.53184 29.7766 1.15139 29.1569 0.890451C28.5373 0.629512 27.8722 0.493186 27.1998 0.489258H6.83981C6.16744 0.493186 5.50246 0.629512 4.88278 0.890451C4.26309 1.15139 3.70089 1.53184 3.22824 2.01006C2.75559 2.48828 2.38168 3.05492 2.12802 3.67762C1.87436 4.30032 1.74589 4.96689 1.74984 5.63926L0.0498931 31.3993C0.0459419 32.0716 0.174407 32.7382 0.428067 33.3609C0.681727 33.9836 1.05551 34.5502 1.52816 35.0285C2.00082 35.5067 2.56314 35.8871 3.18283 36.1481C3.80251 36.409 4.46749 36.5453 5.13986 36.5493H28.8999C29.5731 36.5466 30.2391 36.4113 30.86 36.1509C31.4808 35.8906 32.0442 35.5103 32.5179 35.032C32.9916 34.5536 33.3664 33.9865 33.6207 33.3632C33.875 32.7398 34.0038 32.0725 33.9998 31.3993L32.2899 5.63926ZM26.6198 7.47926C26.6269 9.99459 25.6383 12.4105 23.8698 14.1993C23.0026 15.0797 21.969 15.7789 20.8291 16.2561C19.6891 16.7334 18.4656 16.9792 17.2298 16.9792C15.994 16.9792 14.7705 16.7334 13.6306 16.2561C12.4906 15.7789 11.457 15.0797 10.5898 14.1993C8.8241 12.4095 7.83897 9.99338 7.84982 7.47926H10.1499C10.1499 9.35699 10.8957 11.1578 12.2235 12.4856C13.5512 13.8133 15.3521 14.5593 17.2298 14.5593C19.1076 14.5593 20.9084 13.8133 22.2362 12.4856C23.5639 11.1578 24.3099 9.35699 24.3099 7.47926H26.6198Z" fill="#384454"></path></svg>
            </div> 
          
            <div class="login_button login_button_2 flex a-center j-center">
              <div
              id="langGrou_sj1"
              class="langGroup"
              style="position: relative; display: block"
            >
              <h4 class="flex a-center" @click.stop>
                <!-- <div class="nationalicon" :class="[lang]"></div> -->
                <!-- {{ lang | langFilter }} -->
                <div class="trans_box"></div>
                <!-- <i class="el-icon-caret-bottom"></i> -->
              </h4>
              <div class="national">
                <div class="item flex a-center" @click="langFn('en')">
                  <div class="nationalicon en"></div>
                  ENGLISH
                </div>

                <div class="item flex a-center" @click="langFn('id')">
                  <div class="nationalicon id"></div>
                  INDONESIA
                </div>

                <div class="item flex a-center" @click="langFn('ja')">
                  <div class="nationalicon ja"></div>
                  日本語
                </div>

                <div class="item flex a-center" @click="langFn('ko')">
                  <div class="nationalicon ko"></div>
                  한국어
                </div>

                <div class="item flex a-center" @click="langFn('ms')">
                  <div class="nationalicon ms"></div>
                  MELAYU
                </div>

                <div class="item flex a-center" @click="langFn('ru')">
                  <div class="nationalicon ru"></div>
                  РУССКИЙ
                </div>

                <div class="item flex a-center" @click="langFn('th')">
                  <div class="nationalicon th"></div>
                  ไทย
                </div>

                <div class="item flex a-center" @click="langFn('vi')">
                  <div class="nationalicon vi"></div>
                  TIẾNG VIỆT
                </div>

                <div class="item flex a-center" @click="langFn('zh')">
                  <div class="nationalicon zh"></div>
                  简体中文
                </div>

                <div class="item flex a-center" @click="langFn('zh-tw')">
                  <div class="nationalicon zh-tw"></div>
                  繁体中文
                </div>
              </div>
            </div>
            </div> 
        </div>
      
      </div>
      <div class="phoneMenu" @click="toogNav()">
        <!-- <i :class="phoneIcon"></i> -->
        <svg
          t="1690266589372"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="6712"
          width="32"
          height="32"
        >
          <path
            d="M136.53504 260.48h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.02496s-25.97888-58.02496-58.01984-58.02496H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.02496s25.97888 58.02496 58.02496 58.02496zM887.47008 434.56H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.02496s25.97888 58.02496 58.02496 58.02496h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.02496s-25.97888-58.02496-58.01984-58.02496zM887.47008 714.44992H136.53504c-32.04608 0-58.02496 25.97888-58.02496 58.03008s25.97888 58.03008 58.02496 58.03008h750.93504c32.04096 0 58.01984-25.97888 58.01984-58.03008s-25.97888-58.03008-58.01984-58.03008z"
            p-id="6713"
            fill="#ffffff"
          ></path>
        </svg>
      </div>

      
    </div>
  </div>
</template>

<script>
import { changeLang } from "@/utils/util.js";
export default {
  data() {
    return {
      head_show: true,
      w: 0,
      host: "https://www.interstellarsfx.com/", //固定的网址
      anniu_login: false,
      phoneIcon: "el-icon-more",
      lang: localStorage.getItem("lang") || "en",
    };
  },
  filters: {
    langFilter(name) {
      switch (name) {
        case "en":
          return "ENGLISH";
          break;
        case "ru":
          return "РУССКИЙ";
          break;
        case "zh":
          return "简体中文";
          break;
        case "vi":
          return "TIẾNG VIỆT";
          break;
        case "id":
          return "INDONESIA";
          break;
        case "ja":
          return "日本語";
          break;
        case "ko":
          return "한국어";
          break;
        case "ms":
          return "MELAYU";
          break;
        case "th":
          return "ไทย";
          break;
        case "zh-tw":
          return "繁體中文";
          break;
      }
    },
  },
  methods: {
    toogNav() {
      let nav = document.getElementById("nav");
      let svg_1 = document.getElementById("svg_1");
      let svg_2 = document.getElementById("svg_2");

      if (nav.style.display == "block") {
        nav.style.display = "none";
        svg_2.style.display = "none";
        svg_1.style.display = "block";
        this.phoneIcon = "el-icon-more";
      } else {
        nav.style.display = "block";
        this.phoneIcon = "el-icon-close";
        svg_2.style.display = "block";
        svg_1.style.display = "none";
      }
    },
    // 修改语言然后刷新页面
    langFn(name) {
      let token = localStorage.getItem("token");
      if (token) {
        location.reload();
      } else {
        changeLang(name, this);
      }
    },
    pageTo(key) {
      let _this = this;
      switch (key) {
        case 1:
          _this.$router.push("/");
          break;
        case 6:
          _this.$router.push("/login");
          // const lang = localStorage.getItem("lang");
          // console.log(lang);
          
          // if(lang == 'en' || lang == 'th'){
          //    const href = 'https://tw.islfx.com/login';
          //    window.open(href,"_blank");
          // }else if(lang == 'vi'){
          //   const href = 'https://my.fisg-vn.com/login?link_id=vn';
          //   window.open(href,"_blank");
          // }else{
          //    const href = 'https://my.intsvip.com/login?lang='+lang+"&link_id="+lang;
          //    window.open(href,"_blank");
          // }
          
          
          break;
        case 7:
        _this.$router.push("/");
        break;
        // case 2:
        // 	location.href = 'https://www.interstellarfx.net/zh/trading'
        // 	break;
        // case 3:
        // 	location.href = 'https://www.interstellarfx.net/zh/mt4'
        // 	break;
        // case 4:
        // 	location.href = 'https://www.interstellarfx.net/zh/faq'
        // 	break;
        // case 5:
        // 	location.href = 'https://www.interstellarfx.net/zh/about_us'
        // 	break;
      }
    },
    enter(name) {
      this.$router.push(name);
    },

    jump(url) {
      location.href = url;
    },

    login_init() {
      if (localStorage.getItem("token") ) {
        this.anniu_login = true;
      } else {
        this.anniu_login = false;
      }
    },

    top_close_button() {
      this.head_show = false;
    },

    // Christmas
    xuehua() {
      const NUMBER_OF_LEAVES = 50;

      function init() {
        var container = document.getElementById("leafContainer");
        for (var i = 0; i < NUMBER_OF_LEAVES; i++) {
          container.appendChild(createALeaf());
        }
      }

      function randomInteger(low, high) {
        return low + Math.floor(Math.random() * (high - low));
      }

      function randomFloat(low, high) {
        return low + Math.random() * (high - low);
      }

      function pixelValue(value) {
        return value + "px";
      }

      function durationValue(value) {
        return value + "s";
      }

      function createALeaf() {
        var leafDiv = document.createElement("div");
        var image = document.createElement("img");
        image.src =
          "https://www.fisg.com/wp-content/uploads/2023/12/snow" +
          randomInteger(1, 5) +
          ".png";
        leafDiv.style.top = "-80px";
        leafDiv.style.left = pixelValue(randomInteger(0, window.innerWidth));
        var spinAnimationName =
          Math.random() < 0.5 ? "clockwiseSpin" : "counterclockwiseSpinAndFlip";
        leafDiv.style.webkitAnimationName = "fade, drop";
        image.style.webkitAnimationName = spinAnimationName;
        var fadeAndDropDuration = durationValue(randomFloat(11, 20));
        var spinDuration = durationValue(randomFloat(4, 8));
        leafDiv.style.webkitAnimationDuration =
          fadeAndDropDuration + ", " + fadeAndDropDuration;
        var leafDelay = durationValue(randomFloat(0, 5));
        leafDiv.style.webkitAnimationDelay = leafDelay + ", " + leafDelay;
        image.style.webkitAnimationDuration = spinDuration;
        leafDiv.appendChild(image);
        return leafDiv;
      }
      window.addEventListener("load", init);
    },
  },
  mounted() {
    // const aaa =  navigator.indexOf('iPad');
    // console.log(aaa);
    const userAgent = navigator.userAgent;
    console.log(userAgent);
    const that = this;
    let nav = document.getElementById("nav");
    that.w = window.outerWidth;
    that.ww = window.matchMedia('(max-width: 1160px)');
    console.log(that.ww);
    // if(userAgent.includes('Mobile') || that.ww.matches){
    if(that.ww.matches){
      let list = nav.querySelectorAll("li");
      for (let i = 0; i < list.length; i++) {
        list[i].onclick = function () {
          
          let dom = this.querySelector(".submenu");
          if (dom.style.display == "block") {
            dom.style.display = "none";
            this.querySelector("h4").style.color = "#fff";
            // list[i].querySelector("h4").style.color = "#29907e";
            // dom.classList.remove("color_green")
          } else {
            var menuAll = document.querySelectorAll(".submenu");
            for (i = 0; i < menuAll.length; i++) {
              menuAll[i].style.display = "none";
            }
            var h4All = nav.querySelectorAll("h4");
            for (i = 0; i < h4All.length; i++) {
              h4All[i].style.color = "#fff";
            }
            // for (i = 0; i < list.querySelector("h4").length; i++) {
            //   list[i].querySelector("h4")[i].style.color = "#fff";
            // }
            dom.style.display = "block";
            this.querySelector("h4").style.color = "#29907e";
            // this.style.color = "#29907e"; 
            // dom.classList.add("color_green")

          }
          
        };
      }
    }else{
      let list = nav.querySelectorAll("li");
      for (let i = 0; i < list.length; i++) {
        list[i].querySelector("h4").onmouseover = function () {
          // let dom_h4 = this.querySelector("h4");
          let dom_submenu = list[i].querySelector(".submenu");
          if (dom_submenu.classList.contains('menu_active') == false) {
            dom_submenu.classList.add('menu_active_2');
            dom_submenu.classList.add('menu_active');
          } else {
          }
          
        };

        list[i].onmouseleave = function () {
          console.log(list[i]);
          // let dom_h4 = this.querySelector("h4");
          let dom_submenu = this.querySelector(".submenu");
          if (dom_submenu.classList.contains('menu_active') == true) {
            dom_submenu.classList.remove('menu_active_2');
            dom_submenu.classList.remove('menu_active');
          } else {
            // var menuAll = document.querySelectorAll(".submenu");
            // for (i = 0; i < menuAll.length; i++) {
            //   menuAll[i].classList.remove('menu_active');
            // }
            // dom_submenu.classList.add('menu_active');
          }
        };
      }
    }
    // Christmas
    this.$nextTick(() => {
      this.xuehua();
    });
    let langGroup = document.querySelector(".langGroup");
    langGroup.onclick = function () {
      if (that.w <= 750) {
        let dom = this.querySelector(".national");
        if (dom.style.display == "block") {
          dom.style.display = "none";
        } else {
          dom.style.display = "block";
        }
      }
    };

    window.onresize = function () {
      that.w = window.outerWidth;
      if (window.outerWidth <= 1160) {
        nav.style.display = "none";
        that.phoneIcon = "el-icon-more";
      } else {
        //nav.style.display = 'flex'
        that.phoneIcon = "el-icon-more";
      }
    };
    this.login_init();

    // // localStorage.getItem('lang') || 'en'
    // let tmp_lang = localStorage.getItem('lang') || "en"
    // //console.log("tmp_lang",tmp_lang)
    // tmp_lang = tmp_lang == "en" ? "" : tmp_lang
    // this.host = tmp_lang=="zh"?"https://www.interstellarfx.net/":
    // tmp_lang==""?"https://www.interstellarsfx.com":"https://www.interstellarsfx.com/"
    // this.lang = tmp_lang
    let langText = localStorage.getItem("lang") || "en";
    if (langText == "zh") {
      this.host = "https://www.fisg.com/";
    } else {
      this.host = "https://www.fisg.com/";
    }

    let box = document.getElementById("head_fix_box");
    let h = document.querySelector("#app").offsetHeight;
    if (h > 2000) {
      jQuery(window).scroll(function () {
        if (jQuery(window).scrollTop() > 100) {
          box.style.position = "fixed";
          box.style.width = "100%";
          box.style.left = "0";
          box.style.top = "0";
        } else {
          box.style.position = "relative";
        }
      });
    }
  },
};
</script>

<style scoped="scoped">
.lang-th{
  font-family: "Noto-Sans-Thai";
}
.lang-vi{
  font-family: "NotoSansSC-VI";
}
.head {
  width: 100%;
  background-color: #fff;
  position: relative;
  z-index: 999;
  min-height: auto;
}

.head_top {
  background-color: #212529;
  padding: 12px;
  width: 100%;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  box-sizing: border-box;
}

.head_top_left {
  padding-right: 1%;
}

.head_top_right .el-icon-close:before {
  font-family: element-icons !important;
}

.head_nav_assistant {
  background-color: #f8f9fa;
  height: 58px;
}

.head_nav_assistant ul li {
  line-height: 58px;
  margin-left: 30px;
  font-family: "Lato-Regular", Sans-serif;
}

.head_nav_assistant ul li img {
  width: 100%;
}

.head_nav_assistant_icon {
  width: 12px;
  margin-top: 3px;
  margin-right: 3px;
}

.head_nav_assistant_font {
  color: #6c757d;
  font-size: 14px;
  line-height: 21px;
}

.head_nav_assistant_font a {
  color: #6c757d;
  text-decoration: none;
}

.head_fix {
  position: relative;
  height: auto;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 30%);
}

.head_fix .container {
  height: 100%;
}

.nav_box {
  height: 90px;
}
.right-nav{
  padding:20px 24px 21px 16px;
  text-align: right;
  flex: 1;
  font-size: 15px;
}
.right-nav-m{
  display: none;
}


.head_fix .nav {
  flex: 1;
  height: 65px;
}

.head_fix .nav li {
  line-height: 90px;
  position: relative;
}

.langGroup {
  padding: 0px 0px;
  cursor: pointer;
  font-size: 14px;
  width: auto;
}

.langGroup h4:first-child .nationalicon:first-child {
  display: none !important;
}

.head_fix .nav li h4 {
  padding: 0px 15px;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  font-weight: 700;
  /* font-family: "Lato-Regular", Sans-serif; */
}

.head_fix .nav li .submenu {
  position: absolute;
  left: 0;
  right: 0;
  /* background: #2f3b49; */
  /* padding: 50px 0px 20px 0px; */
  display: none;
  color: #212529;
  height: auto;
  /* transition: transform 0.3s ease-out; */
  opacity: 0;
  /* 		background: url(@/assets/img/beijing_xiala.png); */
}
.menu_active_2{
  display: block !important;
}
.menu_active{
 
  opacity: 1 !important;
  transition: opacity 2s !important;
 
}
.menu_leave{
  opacity: 0 !important;
  transition: opacity .5s !important;

}
/* .head_fix .nav li .submenu:hover{
  display: block;
  opacity: 1 !important;
  transition: opacity 0.5s !important;
}
#submenu_box:hover .submenu{
  display: block;
  opacity: 1 !important;
  transition: opacity 0.5s !important;
} */
.animate {
  transform: translateX(0);
  opacity: 1;
}
.home_schd a {
  color: #000;
  text-decoration: none;
}

.shouye_huan {
  text-decoration: none;
  color: #000;
}

.logo {

  /* width: 190px;
  height: 50px; */
  height: 58px;
  width: 138px;
}

.logo_sj {
  display: block;
}

.logo img {
  height: 58px;
  width: auto;
  /* width: 100%; */
}
/* sanjiao
	.head_sj{ 
		width: 1100px;
		margin: 0 auto;
		position: relative;}
	.head_tb{ 
		position: absolute;
		top: 89px;
		left: 70px;
	}
	

	/*下拉菜单*/

.head_sj {
  width: 1100px;
  margin: 0 auto;
  position: relative;
}
.head_tb {
  position: absolute;
  top: 89px;
  left: 70px;
}

.b_left_anniu_a a {
  background: #e00007;
}

.b_left_anniu_b a {
  background: #2d8319;
}

.b_left_anniu h3 {
  height: 70px;
}

.b_left_anniu h3 a {
  padding: 10px 45px;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
}

.head_fix .nav li:hover {
  /* 		background: #2f3b49;
		background: url(@/assets/img/menubg-1.avif);
		color: #fff; */
  color: #198754 !important;
  /* border-bottom: 2px solid #198754; */
}

.submenu .b_left {
  /* width: 35%; */
  /* margin-top: 150px; */
}
.submenu .b_left b {
  display: none;
  font-size: 2.7em;
  line-height: 2;
  font-weight: normal;
}

.b_left_anniu {
  display: none;
}

.submenu .b_right {
  /* 		width: 600px;
		height: 400px; */
}
.submenu .b_right dl {
  flex: 1;
  width: 260px;
  padding-right: 30px;
}
.submenu .b_right dt {
  font-size: 16px;
  margin-bottom: 15px;
  padding: 20px 20px;
  line-height: 1.2;
  font-weight: bold;
  /* border-bottom: 2px solid #198754; */
  border-bottom: 2px solid #fff;
  margin-right: 32px;
  /* background: url(https://www.fisg.com/wp-content/uploads/2023/02/home_icon07.png); */
  background: url(https://www.fisg.com/wp-content/uploads/2024/09/Vector-4.png);
  background-repeat: no-repeat;
  background-position: 0% 51%;
  background-size: 12px;
  width: 247px;
}


.submenu .b_right dd {
  line-height: 1.7;
  word-wrap: break-word;
  white-space: normal;
  width:200px;
}
.submenu .b_right dd a {
  font-size: 14px;
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 10px 0px;
  font-weight: bold;
}
.submenu .b_right dd a:hover{
  color:#198754;
}
.submenu .b_right dt::before {
}

.b_right_icon_left {
  margin-right: 50px;
  display: none;
}

.b_right_icon_left_tb {
  background: url("@/assets/img/menuicon1.png");
  width: 17px;
  height: 17px;
  background-size: 100% 100%;
  /* margin-top: 36px; */
  margin-right: 5px;
}

.b_right_icon_right {
  display: none;
}

.b_right_icon_right_tb {
  background: url("@/assets/img/menuicon2.png");
  width: 17px;
  height: 17px;
  background-size: 100% 100%;
  /* margin-top: 36px; */
  margin-right: 5px;
}

.b_right_icon_left span img {
  width: 100%;
}

.b_right_icon_left a {
  color: #fff;
  text-decoration: none;
}
.b_right_box_2 .b_right_box{
  width: 95%; 
}
.b_right_box {
  /* width: 90%; */
  /* float: left; */
  /* background: #fff; */
  /* background: #384454; */
  /* background: linear-gradient(-45deg, transparent 70px, #384454 0);; */
  background: #384454;
  padding: 8px 0 8px 32px !important;
  border-radius: 0px 0px 15px 15px;
  display: inline-block;
  color:#fff !important;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 70px), calc(100% - 45px) 100%, 0 100%);
}

.b_right_icon_right span img {
  width: 20%;
}

.b_right_icon_right a {
  color: #fff;
  text-decoration: none;
}
/*下拉菜单End*/

.anniu_guanbi {
  right: 20px;
  position: absolute;
  top: -10px;
  font-size: 24px;
  display: none;
}
.nationalicon {
  width: 16px;
  height: 11px;
  background-color: #000;
  margin-right: 5px;
  display: block;
  /* mk */
}
.trans_box{
  width: 28px;
  height: 28px;
  background: url("../assets/img/trans.png");
  background-size: 100% 100%;
  margin-right:20px;
}
.nationalicon.zh {
  background: url("../assets/img/national/zh.png");
  background-size: 100% 100%;
}

.nationalicon.en {
  background: url("../assets/img/national/en.png");
  background-size: 100% 100%;
}

.nationalicon.ru {  
  background: url("../assets/img/national/ru.png");
  background-size: 100% 100%;
}

.nationalicon.vi {
  background: url("../assets/img/national/vi.png");
  background-size: 100% 100%;
}

.nationalicon.id {
  background: url("../assets/img/national/id.png");
  background-size: 100% 100%;
}

.nationalicon.ja {
  background: url("../assets/img/national/ja.png");
  background-size: 100% 100%;
}

.nationalicon.ko {
  background: url("../assets/img/national/ko.png");
  background-size: 100% 100%;
}

.nationalicon.th {
  background: url("../assets/img/national/th.png");
  background-size: 100% 100%;
}

.nationalicon.ms {
  background: url("../assets/img/national/m.png");
  background-size: 100% 100%;
}

.nationalicon.zh-tw {
  background: url("../assets/img/national/tw.png");
  background-size: 100% 100%;
}

.el-icon-caret-bottom:before {
  font-family: element-icons !important;
}

.national {
  padding-top: 50%;
  position: absolute;
  left: -106px;
  top: 100%;
  z-index: 99;
  width: 100%;
  line-height: 1.5;
  background-color: #fff;
  display: none;
  width: 180px;
}

.national .item {
  width: 100%;
  height: 35px;
  padding: 0px 15px;
  cursor: pointer;
}

.national .item:hover {
  background-color: #eee;
}

.head_fix .nav .langGroup:hover .national {
  display: block;
}

.langGroup:hover .national {
  display: block;
}

.login_button {
  /* height: 45px; */
  /* background-color: #198754; */
  background-color: #fff;
  color: #000;
  cursor: pointer;
  /* margin-top: 16px; */
  /* margin-bottom: 25px; */
  border-radius: 50px;
  /* width:89px; */
  float: right;
  padding:4px 14px 4px 14px;
}
.login_button  svg{
  width: 30px;
  height: 30px;
  margin-bottom: 2px;
}
.login_button_2{
  /* padding:10px 0; */
  padding-right: 8px;
  padding-top:5px;
}
.login_button_3{
  padding-right:10px;
  padding-left: 0;
}
.login_button svg {
  margin-right: 5px;
}

.login_button button {
  background: none;
  border: none;
  color: #fff;
  font-weight: bold;
  /* width: 130px; */
  /* height: 40px; */
  color: #fff;
  cursor: pointer;
  /* padding: 15px 15px 15px 10px; */
  padding: 0 15px;
  font-size: 15px;
  font-family: "Lato-Regular", Sans-serif;
}
.transaction_box{
    /* width:120px; */
    line-height: 40px;
    background-color: #f60;
    /* text-align: center; */
    transform: skewX(-29deg);
    background-image: linear-gradient(to right, #6ea48b, #29907e);
    color: #fff;
    transition: sll 0s;
    padding: 0 30px;
    float: right;
    /* margin-top: 22px; */
    /* margin-bottom: 25px; */
    font-size:15px;
    margin-left:10px;
    display: none;
}
.transaction_box a{
  color:#fff;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap !important;
  transform: skewX(29deg);
  display: inline-block;
}

.phoneMenu {
  display: none;
}

.nav_a {
  position: absolute;
  background: #e00008;
  width: 100%;
  left: 0;
  height: 100%;
  top: 90px;
}

.nav_a_box {
  width: 1100px;
  margin: 0 auto;
  overflow: hidden;
}

.nav_a_box_left {
  float: left;
}

.nav_a_box_right {
  float: right;
}

@media screen and (min-width: 1575px) {
  .transaction_box{
    display: block;
  }
}

/* 	.head_fix .nav li:hover .div{
		display: black;
	} */

@media screen and (min-width: 768px) and (max-width: 1160px) {
  .logo {
    width: 130px;
    height: auto;
    padding: 8px 0px 2px 0px;
  }
  .right-nav-m{
    display: block;
  }
  .right-nav-m .login_button{
    display: block;
    width: auto;
    padding:4px 0 0 0;
    margin:0;
  }
  .submenu .b_right dl {
    flex: 1;
    width: auto;
  }
  .login_button_3{
    padding-right:16px !important;
  }
  .login_button_2{
    padding-right:16px !important;
   
  }
  .right-nav-p{
    display: none;
  }
  .trans_box{
    margin:1px 0 0 0;
  }
  .logo_pc {
    margin:0;
    
    /* display: none; */

  }
  .head_fix .container{
    padding: 2%;
  }
  .logo_sj {
    display: block;
  }

  .logo img {
    height: auto;
    width:100%;
  }

  .logo_sj_box {
    height: 58px;
  }

  .logo_sj_box_left {
    width: 50%;
  }

  .head {
    height: auto;
    min-height: auto !important;
  }

  .head_fix {
    height: auto;
  }

  .header {
    height: auto;
  }

  .nav_box {
    height: auto;
  }

  .head_nav_assistant {
    display: none;
  }

  .phoneMenu {
    width: 100%;
    height: 40px;
    /* 			position: absolute;
					right: 3%; */
    display: none;
    /* 			top: 50%;
					transform: translateY(-50%); */
    font-size: 24px;
    font-weight: 500;
    background: #212529;
    color: #fff;

  }
  .transaction_box{
    display: none;
  }
  .phoneMenu i {
    float: right;
    margin-top: 10px;
    margin-right: 12px;
  }

  .phoneMenu svg {
    float: right;
    margin-top: 5px;
    margin-right: 12px;
  }

  .nav {
    margin-left: 0 !important;
    height: calc(100vh - 65px) !important;
    /* display: none; */
    position: absolute !important;
    background-color: #2f3b49;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: visible;
    height: 50px !important;
    display: flex;
    justify-content: space-evenly;
  }

  .nav li {
    /* width: 100%; */
    /* text-align: center; */
    text-align: left;
    line-height: 50px !important;
    height: auto !important;
  }
  .head_fix .nav li:visited .submenu {
    display: none;
  }
  .nav li h4 {
    color: #fff ;
    font-weight: 400;
   
  }
  .head_fix .nav li h4{
    padding: 0 5px 0 0;
  }
  .national {
    background-color: #fff;
    position: absolute !important;
    top: 30px !important;
    left: -130px;

  }
  .login_button_3 svg{
    width: 25px;
    height: 30px;
  }
  .right-nav{
    padding: 0;
  }
  .login_button svg{
    margin:0;
  }
  .national .item {
    color: #6c757d;
    /* justify-content: center; */
    padding:10px 20px;
  }

  .national .item:hover {
    color: #000;
  }

  .login_button {
    width: 90%;
    margin-left: 5%;
  }

  .head_sj {
    display: none;
  }

  .langGroup {
    line-height: 90px;
    /* padding: 0px 20px; */
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    width: auto;
  }

  .login_button button {
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
  }

  .login_button_sj {
    display: block !important;
    width: auto;
    line-height: 45px;
    margin: 12px;
  }

  /* 下拉菜单 */
  .submenu .b_left {
    display: none;
  }

  .submenu_box_ner {
    display: block;
    padding: 0 !important;
    /* width: 92% !important; */
  }

  .submenu .b_right {
    width: 100%;
    height: auto;
    /* display: flex; */

  }

  .b_right_icon {
    display: none;
  }
  .head_fix .nav li .submenu {
    position: absolute;
    height: auto;
    padding-top: 0;
    padding-bottom: 80px;
    /* background-size: 100% 100%; */
    opacity: 1 !important;
    background: #384454;
    left:0;
    top:50px;
    z-index: 1;
    width: 100vh; 
  }
  .b_right_box_2 .b_right_box{
    width: 100%;
  }
  .b_right_box_2 {
    width: auto !important;
  }

  .line_none {
    display: none;
  }

  .login_button {
    display: none;
  }

  .b_right_box {
    width: auto;
    float: none;
    background: none;
    padding: 8px 32px 8px 32px !important;
    border-radius: 0px 0px 15px 15px;
    display: inline-block;
  }

  .submenu .b_right dt[data-v-4a49499c] {
    color: #fff;
  }

  .submenu .b_right dd a {
    color: #ffffff;
    font-family: "Lato-Regular", Sans-serif;
  }

  #langGrou_sj {
    display: block !important;
    padding: 0px 10px;
    line-height: 45px;
  }
  .integral_sj {
    display: block !important;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    /* width: 100%; */
    height: 100%;
  }
  .head_fix .container{
    padding: 2%;
  }
  .logo_pc {
    padding: 8px 9px 1px 0;
    margin:0;
    /* margin-left: 5px; */ 
    /* display: none; */
  }
  .head_fix .nav li h4 {
    padding: 0px 24px;
    cursor: pointer;
    font-size: 16px;
    white-space: nowrap;
    font-weight: 700;
    /* font-family: "Lato-Regular", Sans-serif; */
  }
  .logo_sj {
    display: block;
  }

  .logo img {
    width: 100%;
    display: inline-block;
    /* margin :8px 0; */
  }

  .logo_sj_box {
    height: 58px;
  }

  .logo_sj_box_left {
    width: 50%;
  }

  .head {
    height: auto;
    min-height: auto !important;
  }

  .head_fix {
    height: auto;
  }

  .header {
    height: auto;
  }

  .nav_box {
    height: auto;
  }

  .head_nav_assistant {
    display: none;
  }
  .transaction_box{
    display: none;
  }
  .phoneMenu {
    width: 100%;
    height: 40px;
    /* 			position: absolute;
			right: 3%; */
    display: none;
    /* 			top: 50%;
			transform: translateY(-50%); */
    font-size: 24px;
    font-weight: 500;
    background: #212529;
    color: #fff;
  }
  .phoneMenu_2{
    width: auto;
    display: block;
    background: none;
    padding-left: 10px;
    padding-top:10px;
    padding-right:2px;
  }
  /* .phoneMenu_2 svg{
    width:30px;
    height:30px;
  } */
  .phoneMenu i {
    float: right;
    margin-top: 10px;
    margin-right: 12px;
  }

  .phoneMenu svg {
    float: right;
    margin-top: 5px;
    margin-right: 12px;
  }

  .nav {
    margin-left: 0 !important;
    height: calc(100vh - 200px) !important;
    display: none;
    position: absolute !important;
    background-color: #2f3b49;
    top: 100%;
    left: 0;
    width: 100%;
    overflow: auto;
    bottom: 0;
  }

  .nav li {
    width: 100%;
    text-align: center;
    text-align: left;
    line-height: 50px !important;
    height: auto !important;
  }
  .head_fix .nav li:visited .submenu {
    display: none;
  }
  .nav li h4 {
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid #fff;
  }

  .national {
    background-color: #fff;
    position: absolute !important;
    top: 61px !important;
    width: 200px;
    left: -100px;
    padding-top: 0;
    
    
    
  }

  .national .item {
    color: #6c757d !important;
    /* justify-content: center; */
    padding-left:20px;
    padding-top:10px;
    padding-bottom: 10px;
  }

  .national .item:hover {
    color: #000;
  }
  .right-nav{
    display: none;
  }
  .right-nav-m{
    display: block !important;
    padding: 2%;
  }

  .login_button {
    /* width: 90%; */
    margin-left: 0;
    /* visibility: hidden; */
    /* display: none; */
    padding: 3px 0 0 0;
    margin-left: 6%;
    margin-right: 1%;
  }
  .login_button_3{
    margin-right: 6px;
  }
  .login_button_3 svg{
    width: 25px;
    height: 30px;
  }
  .login_button_2{
    margin-right: 2px;
  }
  .head_sj {
    display: none;
  }

  .langGroup {
    line-height: 90px;
    /* padding: 0px 20px; */
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    width: auto;
  }

  .login_button button {
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
  }

  .login_button_sj {
    display: block !important;
    width: auto;
    line-height: 45px;
    margin: 12px;
  }

  /* 下拉菜单 */
  .submenu .b_left {
    display: none;
  }

  .submenu_box_ner {
    display: block;
    width: 92% !important;
  }

  .submenu .b_right {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
  }

  .b_right_icon {
    display: none;
  }
  .head_fix .nav li .submenu {
    position: relative;
    height: auto;
    padding-top: 0;
    opacity: 1;
    display: none;
  }

  .line_none {
    display: none;
  }
  .b_right_box {
    width: auto;
    float: none;
    background: none;
    padding: 8px 32px 8px 39px !important;
    border-radius: 0px 0px 15px 15px;
    display: inline-block;
  }

  .submenu .b_right dt {
    color: #fff;
    font-family: "Lato-Regular", Sans-serif;
  }
  .submenu .b_right dt {
    font-size: 16px;
    margin-bottom: 15px;
    padding: 10px 0 0 0;
    line-height: 1.2;
    font-weight: bold;
    /* border-bottom: 2px solid #198754; */
    /* border-bottom: 2px solid #fff; */
    border-bottom: none;
    border-left: none;
    margin-right: 32px;
    /* background: url(https://www.fisg.com/wp-content/uploads/2023/02/home_icon07.png); */
    /* background: url(https://www.fisg.com/wp-content/uploads/2024/09/Vector-4.png); */
    background:none;
    background-repeat: no-repeat;
    background-position: 0% 51%;
    background-size: 12px;
    /* padding-top:10px; */
    position: relative;
  }
  .submenu .b_right dt::before{
    content: '';
    position: absolute;
    transform: translateY(-50%);
    left: -13%;
    top: 67%;
    background: #29907e;
    width: 6px;
    height: 20px;
  }
  .submenu .b_right dd a {
    color: #ffffff;
  }

  #langGrou_sj {
    /* display: block !important; */
    display: none;
    /* padding: 0px 10px; */
    line-height: 45px;
  }
  .integral_sj {
    display: block !important;
  }
  .trans_box{
    margin-right: 4px;
    margin-top:1px;
    width: 28px;
    height: 28px;
  }
  .login_button svg{
    margin-right:0;
  }
}
</style>
<style>
/* remove */

#leafContainer {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

#leafContainer > div {
  position: absolute;
  max-width: 100px;
  max-height: 100px;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-direction: normal, normal;
  -webkit-animation-timing-function: linear, ease-in;
}

#leafContainer > div > img {
  width: 100%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-transform-origin: 50% -100%;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }

  95% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes drop {
  0% {
    -webkit-transform: translate(0px, -50px);
  }

  100% {
    -webkit-transform: translate(0px, 2180px);
  }
}

@-webkit-keyframes clockwiseSpin {
  0% {
    -webkit-transform: rotate(-50deg);
  }

  100% {
    -webkit-transform: rotate(50deg);
  }
}

@-webkit-keyframes counterclockwiseSpinAndFlip {
  0% {
    -webkit-transform: scale(-1, 1) rotate(50deg);
  }

  100% {
    -webkit-transform: scale(-1, 1) rotate(-50deg);
  }
}

#leafContainer,
#leafContainer * {
  pointer-events: none !important;
}
</style>